import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { Canvas, useFrame } from "@react-three/fiber";
import * as ml5 from "ml5";

function Box(props) {
  const mesh = useRef();
  useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01));
  return (
    <mesh {...props} ref={mesh}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={"orange"} />
    </mesh>
  );
}

const WebcamStreamCapture = () => {
  const webcamRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const [objectX, setObjectX] = useState(0);
  const [objectY, setObjectY] = useState(0);
  const [showObject, setShowObject] = useState(false);
  const [videoobj, setVideoObj] = useState(null);

  const canvas = document.getElementById("canvas");

  // object detection
  useEffect(() => {
    let detectionInterval;

    const modelLoaded = () => {
      const height = window.innerHeight;
      const width = window.innerWidth;
      webcamRef.current.video.width = width;
      webcamRef.current.video.height = height;
      detectionInterval = setInterval(() => {
        detect();
      }, 200);
    };

    const objectDetector = ml5.objectDetector("cocossd", modelLoaded);

    const detect = () => {
      if (webcamRef.current.video.readyState !== 4) {
        console.warn("Video not ready yet");
        return;
      }

      objectDetector.detect(webcamRef.current.video, (err, results) => {
        const item = results.find((result) => result.label === "person");
        if (item) {
          const { x, y } = item;

          setShowObject(true);
          setObjectX(x);
          setObjectY(y);
        } else {
          setShowObject(false);
        }
      });
    };

    return () => {
      if (detectionInterval) {
        clearInterval(detectionInterval);
      }
    };
  }, []);

  const handleStartCaptureClick = async () => {
    const stream = canvas.captureStream(); // frames per second
    let options = { mimeType: "video/webm" };

    const mediaRecorder = new MediaRecorder(stream, options);
    mediaRecorder.start(100); // collect 100ms of data
    mediaRecorder.ondataavailable = handleDataAvailable;

    setVideoObj(mediaRecorder);
    setCapturing(true);
  };

  function handleDataAvailable(event) {
    if (event.data && event.data.size > 0) {
      recordedChunks.push(event.data);
      setRecordedChunks(recordedChunks);
    }
  }

  const handleStopCaptureClick = async () => {
    videoobj.stop();
    console.log("Recorded Blobs: ", recordedChunks);
    setCapturing(false);
  };

  const handleDownload = () => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "react-webcam-stream-capture.webm";
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  };

  return (
    <div
      id="testing"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Webcam
        audio={false}
        ref={webcamRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: 1,
        }}
      />

      {showObject && (
        <Canvas
          id="my-canvas"
          style={{
            position: "absolute",
            top: objectY - 100,
            left: objectX - 100,
            zIndex: 2,
          }}
        >
          <ambientLight />
          <Box position={[0, 0, 0]} />
        </Canvas>
      )}
    </div>
  );
};

export default WebcamStreamCapture;
